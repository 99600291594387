.notifications-page-container {
  padding: 0 15px;
}

.table-container {
  margin-bottom: 20px;
  overflow-x: auto; /* Enables horizontal scrolling for smaller screens */
}

.notification-form {
  max-width: 600px; /* Adjust based on your design */
  margin: 0 auto;
}

.notification-form .ant-form-item {
  margin-bottom: 15px;
}

.selected-user-bubble {
  display: inline-block;
  padding: 5px 10px;
  margin: 5px 0;
  background-color: #f0f0f0;
  border-radius: 15px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .notification-form .ant-form-item {
    width: 100%;
    margin-bottom: 15px;
  }

  .notification-form .ant-picker {
    width: 100%;
  }

  .notification-form .ant-btn {
    width: 100%;
  }
}
